import React from 'react';
import { Link, graphql } from 'gatsby';
import P from 'prop-types';

import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Team = ({ data }) => {
  const teams = data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-teams">
      <SEO title="Team" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12 mr-1 ml-1 ingress">
              <h1>Våra konsulter</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="pb-3 mr-1 ml-1">
          Vårt sammansvetsade team har bred kunskap och drar nytta av varandras spetskompetenser.
        </p>
        {teams.map((edge) => {
          const {
            key, name, avatar, jobtitle, linkedin, email,
          } = edge.node.frontmatter.author;

          return (
            <div key={key} className="mb-4 ml-1 mr-1">
              <div className="team card-two">
                <div className="card-header">
                  <div className="card-header-left">
                    {avatar && (
                      <div className="card-image">
                        <Link to={key}>
                          <img
                            alt={name}
                            className="img-fluid mb-2"
                            src={avatar}
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">
                      <Link to={`/konsulter/${key}`}>{edge.node.frontmatter.author.name}</Link>
                    </h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{jobtitle}</strong>
                      </li>
                      <li>
                        <a target="_blank" href={linkedin} rel="noreferrer">
                          linkedin
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${email}`}>{email}</a>
                      </li>
                      <li>
                        <blockquote
                          className="team-content"
                          dangerouslySetInnerHTML={{ __html: edge.node.excerpt }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/konsulter/" } }
      sort: { fields: [frontmatter___sortOrder], order: ASC }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 200)
          frontmatter {
            author {
              name
              avatar
              linkedin
              key
              jobtitle
              email
            }
          }
        }
      }
    }
  }
`;

Team.propTypes = {
  data: P.shape({
    allMarkdownRemark: P.shape({
      edges: P.arrayOf(P.object),
    }).isRequired,
  }).isRequired,
};

export default Team;
